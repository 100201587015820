import React from 'react';
import * as styles from './reviews-section.module.css';
import Container from '../Container';
import SectionTitle from '../SectionTitle';
import TypographyBold from '../TypographyBold';

const data = [
  {
    text: '“The reason I wanted to go Galactic is quite simple… I have a chalkboard of life. Things I want to achieve in my few years on this planet… Nothing could be bigger or better than going into space.”',
    author: 'Peter S.',
    role: 'Consectutar',
  },
  {
    text: '“I will experience what it means to make a childhood dream a reality! To be so closely involved with this historic project is fascinating! I am able to see from very close up how a new era of space travel begins.” ',
    author: 'Marcia C.',
    role: 'Consectutar',
  },
  {
    text: '“Being a part of the Virgin Galactic family of Future Astronauts has always pushed my goals to be that much more bold. We all share an inspiration that is felt among the people building this.”',
    author: 'Kevin K.',
    role: 'Consectutar',
  },
  {
    text: '“I love flying and this is going to be the ultimate flight. To experience the thrill of riding a rocket into space and then being able to wonder in silence at the incredible beauty below is the opportunity of a lifetime.',
    author: 'Francesco L.',
    role: 'Consectutar',
  },
];

const ReviewsSection = () => {
  return (
    <section className={styles.container}>
      <Container>
        <div className={styles.titleWrapper}>
          <SectionTitle>
            What our
            <TypographyBold> Future Astronauts are saying.</TypographyBold>
          </SectionTitle>
        </div>
        <div className={styles.grid}>
          {data.map((item, index) => (
            <figure className={styles.gridItem} key={index}>
              <blockquote>
                <p className={styles.quote}>
                  {item.text}
                </p>
              </blockquote>
              <figcaption>
                <p className={styles.author}>- {item.author}</p>
              </figcaption>
            </figure>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default ReviewsSection;
