import React from 'react';
import * as styles from './benefits-gallery.module.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import slideImage1 from './images/recognition.jpg';
import slideMobImage1 from './images/recognition-mob.jpg';
import slideImage2 from './images/gear.jpg';
import slideMobImage2 from './images/gear-mob.jpg';
import slideImage3 from './images/legacy.jpg';
import slideMobImage3 from './images/legacy-mob.jpg';
import TypographyBold from '../../../TypographyBold';
import classnames from 'classnames';


const BenefitsGallery = () => {
  const settings = {
    arrows: false,
    dots: true,
    fade: true,
    speed: 0,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className={classnames(styles.container, 'gtm-wb-sections-membership-benefits-carousel')}>
      <Slider {...settings}>
        <div>
          <div className={styles.slideInner}>
            <div className={styles.contentWrapper}>
              <div className={styles.content}>
                <div className={styles.contentInner}>
                  <h3 className={styles.slideTitle}>
                    Recognizing
                    <TypographyBold> your spaceflight</TypographyBold>
                  </h3>
                  <p className={styles.contentParagraph}>
                    All those who fly will be recognized as commercial astronauts by the Association of Space Explorers with a specially commissioned insignia. Since 1985, the organization counts over 400 astronauts from 27 different countries as members. The insignia is awarded with your unique Virgin Galactic astronaut wings, post-flight.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.imageContainer}>
              <picture className={styles.imageWrapper}>
                <source srcSet={slideMobImage1} media='(max-width: 768px)'/>
                <img className={classnames(styles.image, styles.zoomable)} src={slideImage1} alt='Astronauts'/>
              </picture>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.slideInner}>
            <div className={styles.contentWrapper}>
              <div className={styles.content}>
                <div className={styles.contentInner}>
                  <h3 className={styles.slideTitle}>
                    Your
                    <TypographyBold> Virgin Galactic keepsakes</TypographyBold>
                  </h3>
                  <p className={styles.contentParagraph}>
                    It is imperative to us that you are able to completely immerse yourself in your spaceflight. That’s why we’ll provide a full media package of images & video from the flight, captured by 16 cameras in the cabin, to allow you to relive and share your experience with the world. The insignia is awarded post-flight.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.imageContainer}>
              <picture className={styles.imageWrapper}>
                <source srcSet={slideMobImage2} media='(max-width: 768px)'/>
                <img className={styles.image} src={slideImage2} alt='Astronaut'/>
              </picture>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.slideInner}>
            <div className={styles.contentWrapper}>
              <div className={styles.content}>
                <div className={styles.contentInner}>
                  <h3 className={styles.slideTitle}>
                    Beyond
                    <TypographyBold> your flight</TypographyBold>
                  </h3>
                  <p className={styles.contentParagraph}>
                    The community offers the potential for lifelong membership. Share your experience with others as the first generation of commercial astronauts, and use your renewed perspective to collaborate on post-flight missions.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.imageContainer}>
              <picture className={styles.imageWrapper}>
                <source srcSet={slideMobImage3} media='(max-width: 768px)'/>
                <img className={styles.image} src={slideImage3} alt='Space'/>
              </picture>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default BenefitsGallery;
