import React from 'react';
import * as styles from './advertising-section.module.css';
import Container from '../Container';
import firstImage from './images/connections.jpg';
import firstMobImage from './images/connections-mob.jpg';
import secondImage from './images/tour.jpg';
import secondMobImage from './images/tour-mob.jpg';
import thirdImage from './images/gain.jpg';
import thirdMobImage from './images/gain-mob.jpg';
import fourthImage from './images/give-back.jpg';
import fourthMobImage from './images/give-back-mob.jpg';

const data = [
  {
    image: firstImage,
    mobImage: firstMobImage,
    alt: 'Astronauts',
    description: 'Connect with a fascinating network of people who are on the Virgin Galactic journey to space.'
  },
  {
    image: secondImage,
    mobImage: secondMobImage,
    alt: 'Space',
    description: 'Connect with the Virgin Galactic team and enjoy exclusive access to our manufacturing and operational facilities. '
  },
  {
    image: thirdImage,
    mobImage: thirdMobImage,
    alt: 'People',
    description: 'Gain exclusive access to unique money-can’t-buy events, experiences, activities and membership perks.'
  },
  {
    image: fourthImage,
    mobImage: fourthMobImage,
    alt: 'People',
    description: 'Give back through our nonprofit program, Galactic Unite – using the inspiration of human spaceflight as a power for good.'
  },
];

const AdvertisingSection = () => {
  return (
    <section className={styles.container}>
      <Container>
        <div className={styles.grid}>
          {data.map((item, index) => (
            <div className={styles.gridItem} key={index}>
              <div className={styles.gridItemImageWrapper}>
                <picture>
                  <source srcSet={item.mobImage} media='(max-width: 768px)'/>
                  <img src={item.image} className={styles.gridItemImage} alt={item.alt} />
                </picture>
              </div>
              <p className={styles.gridItemText}>
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default AdvertisingSection;
