import React from 'react';
import * as styles from './value-section.module.css';
import Container from '../Container';
import astronautsImage from './images/astronauts.jpg';
import mobAstronautsImage from './images/mob-astronauts.jpg';


const ValueSection = () => {
  return (
    <section className={styles.container}>
      <Container>
        <div className={styles.inner}>
          <div className={styles.content}>
            <p className={styles.contentParagraph}>
              Human spaceflight is a profound and transformative experience and the pinnacle of the Virgin Galactic astronaut offering. But the true value lies in the entire astronaut experience, from the moment you book until well after your flight. When you secure your reservation to space, you will also gain membership to a unique and long-established global community of Future Astronauts with a shared love of adventure and passion for positive change.
            </p>
            <p className={styles.contentParagraph}>
              Together, you’ll anticipate and prepare for your spaceflight, sharing incredible events and experiences along the way.
            </p>
          </div>
          <div className={styles.imageContainer}>
            <picture className={styles.imageWrapper}>
              <source srcSet={mobAstronautsImage} media='(max-width: 768px)'/>
              <img className={styles.image} src={astronautsImage} alt='Astronauts'/>
            </picture>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ValueSection;
