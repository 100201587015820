import React from 'react';
import * as styles from './lifetime-benefits-section.module.css';
import Container from '../Container';
import SectionTitle from '../SectionTitle';
import TypographyBold from '../TypographyBold';
import BenefitsGallery from './components/BenefitsGallery';

const LifetimeBenefitsSection = () => {
  return (
    <section className={styles.container}>
      <Container>
        <div className={styles.inner}>
          <div className={styles.titleWrapper}>
            <SectionTitle>
              A lifetime
              <TypographyBold> of benefits.</TypographyBold>
            </SectionTitle>
          </div>
          <p className={styles.description}>
            Beyond invitations to exclusive events and experiences, the Virgin Galactic membership is an opportunity to create a lasting impact and legacy, recognized with unique insignia and mementos.
          </p>
        </div>
      </Container>
      <Container size='lg'>
        <BenefitsGallery />
      </Container>
    </section>
  );
};

export default LifetimeBenefitsSection;
