// extracted by mini-css-extract-plugin
export var container = "benefits-gallery-module--container--GWgLu";
export var slideInner = "benefits-gallery-module--slide-inner--fkLI8";
export var contentWrapper = "benefits-gallery-module--content-wrapper--4qZEx";
export var content = "benefits-gallery-module--content--79dWS";
export var contentInner = "benefits-gallery-module--content-inner--+Ic2z";
export var slideTitle = "benefits-gallery-module--slide-title--jbz7-";
export var contentParagraph = "benefits-gallery-module--content-paragraph--5QBF-";
export var imageContainer = "benefits-gallery-module--image-container--N8MpJ";
export var imageWrapper = "benefits-gallery-module--image-wrapper--EbiBL";
export var image = "benefits-gallery-module--image--fP92v";
export var zoomable = "benefits-gallery-module--zoomable--2EYGH";