import React from 'react';
import Layout from '../components/Layout';
import * as styles from './membership.module.css';
import Container from '../components/Container';
import PageTitle from '../components/PageTitle';
import ReadyToBecomeAstronautSection from '../components/ReadyToBecomeAstronautSection';
import previewImg from './images/membership-page-preview-section-bg.jpg';
import previewMobImg from './images/membership-page-preview-section-bg-mob.jpg';
import PreviewSection from '../components/PreviewSection';
import LifetimeBenefitsSection from '../components/LifetimeBenefitsSection';
import AdvertisingSection from '../components/AdvertisingSection';
import TypographyBold from '../components/TypographyBold';
import ValueSection from '../components/ValueSection';
import ReviewsSection from '../components/ReviewsSection';
import PreviewSectionInner from '../components/PreviewSectionInner';

const MembershipPage = () => {
  return (
    <Layout pageTitle='Virgin Galactic | Membership' description='As soon as you purchase your spaceflight, you become a member of the Virgin Galactic Future Astronaut community.'>
      <PreviewSection image={previewImg} mobImage={previewMobImg} alt='Space'>
        <Container size='sm'>
          <PreviewSectionInner>
            <div className={styles.pageTitleWrapper}>
              <div className={styles.subtitle}>
                The Future Astronaut community:
              </div>
              <PageTitle>
                there’s nothing like it,
                <TypographyBold> on – or off – Earth.</TypographyBold>
              </PageTitle>
            </div>
          </PreviewSectionInner>
        </Container>
      </PreviewSection>
      <ValueSection />
      <AdvertisingSection />
      <LifetimeBenefitsSection />
      <ReviewsSection />
      <ReadyToBecomeAstronautSection />
    </Layout>
  );
};

export default MembershipPage;
